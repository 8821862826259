// node_modules dependencies
import Component from 'vue-class-component'
import { Watch, Vue } from 'vue-property-decorator'
import isEmpty from 'lodash/isEmpty'
import lowerCase from 'lodash/lowerCase'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import forEach from 'lodash/forEach'
import startCase from 'lodash/startCase'
import sortBy from 'lodash/sortBy'
import split from 'lodash/split'
import upperCase from 'lodash/upperCase'
import last from 'lodash/last'

// Base Page
import BasePage from '@/pages/Base'

// Services
import CategoriesService from '@/services/Categories'
import Rules from '@/services/Rules'
import CountryService from '@/services/Country'
import HscodesService from '@/services/Hscodes'
import Categories from '@/services/Categories'
import ProductService from '@/services/Products'

@Component({})
export default class CategoryRulesIndex extends BasePage {
  constructor() {
    super()
  }

  // Temp category format
  category_format_temp: ''
  weight_unit_temp: ''
  /* Categories */
  categories_data: any = []
  opts: any = {
    params: {
      'filter[level][is]': '1',
      'include': 'child'
    }
  }

  categories: any = {
    level_1: {
      selected: null,
      keyword: null,
      loading: false,
      items: []
    },
    level_2: {
      selected: null,
      keyword: null,
      loading: false,
      items: []
    },
    level_3: {
      selected: null,
      keyword: null,
      loading: false,
      items: []
    }
  }

  //Table List
  formExpanded = []
  expanded = []
  tableHeadersList: object[] = [
    {
      text: 'ID',
      align: 'center',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Description',
      align: 'center',
      sortable: true,
      value: 'description'
    },
    {
      text: 'Category',
      align: 'center',
      sortable: true,
      value: 'category_name'
    },
    {
      text: 'Weight',
      align: 'center',
      sortable: false,
      value: 'weight'
    },
    {
      text: 'Quantity (PCS) / CBM',
      align: 'center',
      sortable: true,
      value: 'qty_cbm'
    },
    // {
    //   text: 'Min Pesanan Quantity Category Level 3',
    //   align: 'center',
    //   sortable: true,
    //   value: 'min_level_3_order',

    // },
    // {
    //   text: 'Min Pesanan PCS per SKU',
    //   align: 'center',
    //   sortable: true,
    //   value: 'min_product_order',
    // },
    {
      text: 'HS Code',
      align: 'center',
      sortable: true,
      value: 'hscode_number'
    },
    // {
    //   text: 'Status',
    //   align: 'center',
    //   sortable: false,
    //   value: 'status',
    // },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action'
    }
  ]

  //Category List
  showCategoryRule: boolean = false
  selectedCategoryRule: any = null
  categoryrule: any = {
    items: [],
    selected: [],
    keyword: null,
    loading: false
  }
  selectedDelete: any = null
  showDeleteModal: boolean = false

  // HS Code
  hscodes: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }

  //Country
  countries: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }
  searchDebounce: Function

  // Category Rules Table
  defaultRule: any = {}
  showRulesTable: boolean = false
  tableHeadersRule: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Description',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Berat (Kg)',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Weight Unit',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Quantity (PCS) / CBM',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Note',
      align: 'center',
      sortable: false,
      class: 'small-table-cell-width'
    },
    // {
    //   text: '(CW) Can Wholesale?',
    //   align: 'center',
    //   sortable: false,
    //   class: 'table-cell-width'
    // },
    // {
    //   text: '(CR) Can Retail?',
    //   align: 'center',
    //   sortable: false,
    //   class: 'table-cell-width'
    // },
    {
      text: 'Min Pesanan PCS per SKU',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Min Pesanan Quantity Category Level 3',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Country',
      align: 'center',
      sortable: 'false',
      class: 'table-cell-width'
    },
    {
      text: 'Hscode',
      align: 'center',
      sortable: false,
      class: 'table-cell-width-hscode'
    },
    {
      text: 'Pajak - BM',
      align: 'center',
      sortable: false,
      class: 'small-table-cell-width'
    },
    {
      text: 'Pajak - PPN',
      align: 'center',
      sortable: false,
      class: 'small-table-cell-width'
    },
    {
      text: 'Pajak - PPH',
      align: 'center',
      sortable: false,
      class: 'small-table-cell-width'
    },
    {
      text: 'Total Pajak',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableItemsEdit: object[] = []
  tableItemsList: any = []
  tableTotalItemsList: number | string = 0
  tableTotalItems: number | string = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true
  tableLoadingList: boolean = true
  tableLoadingEdit: boolean = true
  noteItems: any = [{ text: 'O', value: 'o' }, { text: 'U', value: 'u' }, { text: 'X', value: 'x' }]
  weightUnitItems: any = [{ text: 'Kg', value: 'KG' }, { text: 'Gram', value: 'GR' }, { text: 'Pounds', value: 'LBS' }, { text: 'Ounce', value: 'OZ' }]
  idHSCodes: string = ''
  idCategory: any = []
  idCountry: any = ''
  addNew: boolean = false
  editNew: boolean = false
  parent2: string = ''

  categoryrulelist: any = []

  // add category rules
  addCategoryRulesDialog: boolean = false
  addCategoryRules: any = {
    description: '',
    weight: '',
    weightUnit: '',
    cbm: '',
    hscode: [],
    category_format: 'unknown',
    can_wholesale: 0,
    can_retail: 0,
    category: ''
  }
  // weightUnitItems: any = []

  // attach category rules
  attachCategoryDialog: boolean = false
  attachCategory: any = {}
  categoryItems: any = []
  hscodeItems: any = []

  async mounted() {
    // await this.getCategories()
    // await this.getCountries()
    await this.getCategoryRulesList()

    // this.searchDebounce = debounce(this.getHscodes, 500)
  }
  async getCategories() {
    try {
      this.categories.level_1.loading = true
      const responseCategories = await ProductService.getTopLevelCategories(this.opts)
      const responseData = responseCategories.data.data
      this.categories_data = responseData
      this.categories.level_1.items = []
      forEach(responseData, categoryLevel1 => {
        var level1_item = {
          id: categoryLevel1.attributes.id,
          name: categoryLevel1.attributes.name
        }
        this.categories.level_1.items.push(level1_item)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.categories.level_1.loading = false
    }
  }

  async getCountries() {
    try {
      this.countries.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.countries.items = []
      for (const dataCountry of responseData) {
        this.countries.items.push(dataCountry.attributes)
      }
      this.countries.items = sortBy(this.countries.items, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.countries.loading = false
    }
  }

  @Watch('searchInput', { deep: true })
  onChanged() {
    this.getCategoryRulesList()
  }

  @Watch('hscodes.keyword')
  onChangedHsCode() {
    if (this.hscodes.keyword !== null && this.hscodes.keyword !== '') {
      const splits = split(this.hscodes.keyword, ' - ', 3)
      if (isEmpty(splits[1])) {
        this.getHscodesSearch()
      }
    }
  }


  // @Watch('addCategoryRules.hscode')
  // onHSCodesChanged() {
  //   if (this.addCategoryRules.hscode !== null && this.addCategoryRules.hscode !== '' && this.addCategoryRules.hscode !== undefined) {
  //     this.getHscodesById(this.addCategoryRules.hscode)
  //   }
  // }
  // @Watch('idHSCodes')
  // onHSCodesChanged() {
  //   if (this.idHSCodes !== null && this.idHSCodes !== '' && this.idHSCodes !== undefined) {
  //     this.getHscodesById(this.idHSCodes)
  //   }
  // }

  @Watch('tablePagination', { deep: true })
  onTablePaginationChanged() {
    this.$vuetify.goTo(0)
    this.getCategoryRulesList()
  }
  @Watch('filterSelected', { deep: true })
  onFilterSelectedCanged() {
    this.getCategoryRulesList()
  }

  // Filter by Status //
  filterLoading: boolean = false
  filterLabel: string = 'Filter by Status'
  filterHint: string = 'Apply Status Filters Here'
  filterItems: string[] = ['Incompleted Rules', 'Have Completed Rules']
  filterIcon: string = 'mdi-filter-variant'
  filterSelected: string = ''

  // async getCategoryRules() {
  //   try {
  //     this.hscodes.loading = true
  //     const opts: any = {
  //       params: {

  //       }
  //     }
  //     if (!isEmpty(this.hscodes.keyword)) {
  //       opts.params.search = `code:${this.hscodes.keyword};country.name:${this.hscodes.keyword}`
  //       opts.params.searchJoin = 'or'
  //     } else {
  //       delete opts.params.search
  //       delete opts.params.searchJoin
  //     }
  //     const response = await HscodesService.getHscodes(opts)
  //     const responseData = response.data.data
  //     const responseInclude = response.data.included
  //     this.hscodes.items = []
  //     this.hscodes.id = ''
  //     for (const dataHscode of responseData) {
  //       // const taxBM = find(dataHscode.taxes, tax => lowerCase(tax.label) === 'bm')
  //       // const taxPPN = find(dataHscode.taxes, tax => lowerCase(tax.label) === 'ppn')
  //       // const taxPPH = find(dataHscode.taxes, tax => lowerCase(tax.label) === 'pph')
  //       const taxBM = 0
  //       const taxPPN = 0
  //       const taxPPH = 0
  //       const country_id = dataHscode.attributes.country_id
  //       // const hscode = {
  //       //   ...dataHscode,
  //       //   displayText: `${dataHscode.attributes.code} - ${responseInclude.country[country_id].attributes.name}`,
  //       //   taxBM: taxBM.value,
  //       //   taxPPN: taxPPN.value,
  //       //   taxPPH: taxPPH.value
  //       // }
  //       const hscode = {
  //         ...dataHscode,
  //         displayText: `${dataHscode.attributes.code} - ${responseInclude.country[country_id].attributes.name}`,
  //         code: dataHscode.attributes.code,
  //         taxBM: taxBM,
  //         taxPPN: taxPPN,
  //         taxPPH: taxPPH
  //       }
  //       this.hscodes.items.push(hscode)
  //     }
  //   } catch (error) {
  //     this.catchHandler(error)
  //   } finally {
  //     this.hscodes.loading = false
  //   }
  // }

  async getHscodes() {
    try {
      this.hscodes.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at'
          // 'filter[country_id][is]': this.idCountry
          // 'filter[country_id][is]': this.addCategoryRules.country
        }
      }
      if (this.attachCategory.country) {
        var textFilter = 'filter[country_id][is]'
        opts.params[textFilter] = this.attachCategory.country
      } else {
        var textFilter = 'filter[country_id][is]'
        delete opts.params[textFilter]
      }

      const response = await HscodesService.getHscodes(opts)
      const responseData = response.data.data
      const responseInclude = response.data.included
      this.hscodes.items = []
      if (!isEmpty(this.attachCategory.hscode)) {
        for (const hscodeSelected of this.attachCategory.hscode) {
          const hscode = {
            ...hscodeSelected,
            displayText: `${hscodeSelected.attributes.code} - ${hscodeSelected.attributes.country_name} - ${hscodeSelected.attributes.description}`,
            code: hscodeSelected.attributes.code
          }
          this.hscodes.items.push(hscode)
        }
      }
      for (const dataHscode of responseData) {
        const country_id = dataHscode.attributes.country_id
        const hscode = {
          ...dataHscode,
          displayText: `${dataHscode.attributes.code} - ${responseInclude.country[country_id].attributes.name} - ${dataHscode.attributes.description}`,
          code: dataHscode.attributes.code
        }
        this.hscodes.items.push(hscode)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.hscodes.loading = false
    }
  }

  async getHscodesById(id) {
    const responseHscodes = await HscodesService.getHscodeById(id)
    const responseHscodesData = responseHscodes.data.data
    const country_idHscodes = responseHscodesData.attributes.country_id
    const hscodeHscodes = {
      ...responseHscodes.data.data,
      displayText: `${responseHscodesData.attributes.code} - ${responseHscodes.data.included.country[country_idHscodes].attributes.name} - ${responseHscodesData.attributes.description}`,
      code: responseHscodesData.attributes.code
    }
    this.hscodes.items.push(hscodeHscodes)

    // this.defaultRule.quick_tax_details = responseHscodesData.attributes.taxes
    // this.addCategoryRules.quick_tax_details = responseHscodesData.attributes.taxes

    if (Array.isArray(responseHscodesData.attributes.taxes)) {
      // console.log('INISIALISASI TAX')
      // this.defaultRule.taxBM = find(responseHscodesData.attributes.taxes, tax => lowerCase(tax.label) === 'bm')
      // this.defaultRule.taxBM = this.defaultRule.taxBM === undefined ? 0 : this.defaultRule.taxBM.value

      // this.defaultRule.taxPPN = find(responseHscodesData.attributes.taxes, tax => lowerCase(tax.label) === 'ppn')
      // this.defaultRule.taxPPN = this.defaultRule.taxPPN === undefined ? 0 : this.defaultRule.taxPPN.value

      // this.defaultRule.taxPPH = find(responseHscodesData.attributes.taxes, tax => lowerCase(tax.label) === 'pph')
      // this.defaultRule.taxPPH = this.defaultRule.taxPPH === undefined ? 0 : this.defaultRule.taxPPH.value

      this.addCategoryRules.taxBM = find(responseHscodesData.attributes.taxes, tax => lowerCase(tax.label) === 'bm')
      this.addCategoryRules.taxBM = this.addCategoryRules.taxBM === undefined ? 0 : this.addCategoryRules.taxBM.value

      this.addCategoryRules.taxPPN = find(responseHscodesData.attributes.taxes, tax => lowerCase(tax.label) === 'ppn')
      this.addCategoryRules.taxPPN = this.addCategoryRules.taxPPN === undefined ? 0 : this.addCategoryRules.taxPPN.value

      this.addCategoryRules.taxPPH = find(responseHscodesData.attributes.taxes, tax => lowerCase(tax.label) === 'pph')
      this.addCategoryRules.taxPPH = this.addCategoryRules.taxPPH === undefined ? 0 : this.addCategoryRules.taxPPH.value

      this.addCategoryRules.totalTax = this.addCategoryRules.taxBM + this.addCategoryRules.taxPPN + this.addCategoryRules.taxPPH
    } else {
      // console.log('INISIALISASI TAX TIDAK')
      // this.defaultRule.taxBM = 0
      // this.defaultRule.taxPPN = 0
      // this.defaultRule.taxPPH = 0
      // this.idHSCodes = ''
      this.addCategoryRules.taxBM = 0
      this.addCategoryRules.taxPPN = 0
      this.addCategoryRules.taxPPH = 0
      this.addCategoryRules.totalTax = 0
      this.addCategoryRules.hscode = ''
      this.getHscodes()
    }
  }

  async getHscodesSearch() {
    try {
      this.hscodes.loading = true
      this.hscodes.items = []
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at'
        }
      }
      if (this.hscodes.keyword !== null && this.hscodes.keyword !== '') {
        var textFilter = 'filter[code][like]'
        opts.params[textFilter] = this.hscodes.keyword
      } else {
        var textFilter = 'filter[code][like]'
        delete opts.params[textFilter]
      }
      const responseco = await HscodesService.getHscodes(opts)
      const responseDataCo = responseco.data.data
      const responseIncludeCo = responseco.data.included
      for (const dataHscode of responseDataCo) {
        const country_id = dataHscode.attributes.country_id
        const hscode = {
          ...dataHscode,
          displayText: `${dataHscode.attributes.code} - ${responseIncludeCo.country[country_id].attributes.name} - ${dataHscode.attributes.description}`,
          code: dataHscode.attributes.code
        }
        this.hscodes.items.push(hscode)
      }
      const optsdesc: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at'
        }
      }
      if (this.hscodes.keyword !== null && this.hscodes.keyword !== '') {
        var textFilter = 'filter[description][like]'
        opts.params[textFilter] = this.hscodes.keyword
      } else {
        var textFilter = 'filter[description][like]'
        delete opts.params[textFilter]
      }
      const responsedes = await HscodesService.getHscodes(optsdesc)
      const responseDataDes = responsedes.data.data
      const responseIncludeDes = responsedes.data.included
      for (const dataHscode of responseDataDes) {
        const country_id = dataHscode.attributes.country_id
        const hscode = {
          ...dataHscode,
          displayText: `${dataHscode.attributes.code} - ${responseIncludeDes.country[country_id].attributes.name} - ${dataHscode.attributes.description}`,
          code: dataHscode.attributes.code
        }
        this.hscodes.items.push(hscode)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.hscodes.loading = false
    }
  }

  changeCountry() {
    // if (this.idCountry) {
    // if (this.addCategoryRules.country) {
    // this.defaultRule.taxBM = 0
    // this.defaultRule.taxPPN = 0
    // this.defaultRule.taxPPH = 0
    // this.idHSCodes = ''

    // this.addCategoryRules.taxBM = 0
    // this.addCategoryRules.taxPPN = 0
    // this.addCategoryRules.taxPPH = 0
    // this.addCategoryRules.hscode = ''
    this.getHscodes()
    // } else {
    //   this.hscodes.items = []
    // }
  }

  // @Watch('categories.level_1.selected')
  async onCategorylevel1Changed(category) {
    try {
      if (this.categories.level_1.selected) {
        this.categories.level_2.loading = true
        this.idCategory = []
        this.idCategory.push(this.categories.level_1.selected.id)
        const response = await ProductService.getLowLevelCategories(category.id)
        const responseData = response.data.data
        this.categories.level_2.items = []
        this.categories.level_3.items = []
        for (const categoryLevel2 of responseData) {
          if (!isEmpty(categoryLevel2.label) && categoryLevel2.level === 2)
            this.categories.level_2.items.push(categoryLevel2)
        }
        forEach(responseData, categoryLevel2 => {
          if (categoryLevel2.attributes.level === 2) {
            var level2_item = {
              id: categoryLevel2.attributes.id,
              name: categoryLevel2.attributes.name
            }
            this.categories.level_2.items.push(level2_item)
          }
        })
        this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.categories.level_1.selected.name) + ':default'
      } else {
        this.categories.level_2.items = []
        this.categories.level_3.items = []
        this.categories.level_2.selected = null
        this.categories.level_3.selected = null
        this.idCategory = []
        this.addCategoryRules.category_format = 'unknown'
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.categories.level_2.loading = false
    }
  }

  // @Watch('categories.level_2.selected')
  async onCategorylevel2Changed(category) {
    try {
      if (this.categories.level_2.selected) {
        this.categories.level_3.loading = true
        this.idCategory.push(this.categories.level_2.selected.id)
        const response = await ProductService.getLowLevelCategories(category.id)
        const responseData = response.data.data
        this.categories.level_3.items = []
        for (const categoryLevel3 of responseData) {
          if (!isEmpty(categoryLevel3.label) && categoryLevel3.level === 3)
            this.categories.level_3.items.push(categoryLevel3)
        }
        forEach(responseData, categoryLevel3 => {
          if (categoryLevel3.attributes.level === 3) {
            var level3_item = {
              id: categoryLevel3.attributes.id,
              name: categoryLevel3.attributes.name
            }
            this.categories.level_3.items.push(level3_item)
          }
        })
        this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.categories.level_1.selected.name) + ':' + lowerCase(this.categories.level_2.selected.name) + ':default'
      } else {
        this.categories.level_3.items = []
        this.categories.level_3.selected = null
        this.idCategory.splice(1)
        this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.categories.level_1.selected.name) + ':default'
      }

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.categories.level_3.loading = false
    }
  }

  // @Watch('categories.level_3.selected')
  async onCategorylevel3Changed(category) {
    if (this.categories.level_3.selected) {
      this.idCategory.push(this.categories.level_3.selected.id)
      this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.categories.level_1.selected.name) + ':' + lowerCase(this.categories.level_2.selected.name) + ':' + lowerCase(this.categories.level_3.selected.name) + ':default'
    } else {
      this.idCategory.splice(2)
      this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.categories.level_1.selected.name) + ':' + lowerCase(this.categories.level_2.selected.name) + ':default'
    }

  }
  // async onCategorylevel3Changed(category) {
  //   try {
  //     this.showRulesTable = true
  //     this.tableLoading = true
  //     this.tableItems = []
  //     this.addNew = false
  //     this.idHSCodes = ''
  //     this.idCategory = ''
  //     this.idCountry = ''
  //     this.defaultRule = []
  //     const responseCategory = await Categories.getCategoryById(this.categories.level_3.selected.id)
  //     this.idCategory = this.categories.level_3.selected.id
  //     const responseIncludeCategory = responseCategory.data.included.rules

  //     if (isEmpty(responseIncludeCategory)) {
  //       this.addNew = true
  //       // this.defaultRule.can_retail = 0
  //       // this.defaultRule.can_wholesale = 0
  //       this.defaultRule.category_format = 'wholesale:' + lowerCase(this.categories.level_1.selected.name) + ':' + lowerCase(this.categories.level_2.selected.name) + ':' + lowerCase(this.categories.level_3.selected.name) + ':default'
  //       this.defaultRule.category_level_4 = 'Default'
  //       this.defaultRule.min_level_3_order = null
  //       this.defaultRule.min_product_order = null
  //       this.defaultRule.note = 'o'
  //       this.defaultRule.description = null
  //       this.defaultRule.qty_cbm = null
  //       this.defaultRule.quick_tax_details = null
  //       this.defaultRule.total_tax = 0
  //       this.defaultRule.taxBM = 0
  //       this.defaultRule.taxPPN = 0
  //       this.defaultRule.taxPPH = 0
  //       this.defaultRule.weight = null
  //       this.defaultRule.weight_unit = 'KG'
  //       this.tableItems.push(this.defaultRule)
  //     } else {
  //       forEach(responseIncludeCategory, categoryRules => {
  //         Rules.getCategoryRulesById(categoryRules.id)
  //           .then(responseCategoryRules => {
  //             const responseDataCategoryRules = responseCategoryRules.data.data
  //             const responseIncludeCategoryRules = responseCategoryRules.data.included

  //             this.defaultRule = responseDataCategoryRules.attributes

  //             if (isEmpty(responseDataCategoryRules.relationships.hscodes)) {
  //               this.defaultRule.taxBM = 0
  //               this.defaultRule.taxPPN = 0
  //               this.defaultRule.taxPPH = 0
  //               this.getHscodes()
  //             } else {
  //               this.idHSCodes = responseDataCategoryRules.relationships.hscodes[0].id
  //               this.idCountry = responseIncludeCategoryRules.hscodes[this.idHSCodes].attributes.country_id
  //               this.getHscodes()
  //               this.getHscodesById(responseDataCategoryRules.relationships.hscodes[0].id)
  //             }
  //             this.defaultRule.weight_unit = upperCase(this.defaultRule.weight_unit)
  //             this.defaultRule.category_level_4 = responseDataCategoryRules.attributes.id
  //             this.tableItems.push(this.defaultRule)
  //           })
  //           .catch(error => this.catchHandler(error))
  //       })
  //     }
  //   } catch (error) {
  //     this.catchHandler(error)
  //   } finally {
  //     this.tableLoading = false
  //   }
  // }

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search Description Here...'
  searchHint: string = 'Search Description Here'
  searchIcon: string = 'search'

  // saveRule(props) {
  saveRule() {
    this.$validator.validateAll('addCategoryRulesScope').then(async response => {
      if (response) {
        try {
          // props.item.saveLoading = true
          // const payload = {
          //   weight: props.item.weight,
          //   weight_unit: props.item.weight_unit,
          //   description: props.item.description,
          //   qty_cbm: props.item.qty_cbm,
          //   min_product_order: props.item.min_product_order,
          //   min_level_3_order: props.item.min_level_3_order,
          //   category_format: props.item.category_format,
          //   note: lowerCase(props.item.note),
          //   can_wholesale: props.item.can_wholesale === null ? 0 : props.item.can_wholesale,
          //   can_retail: props.item.can_retail === null ? 0 : props.item.can_retail,
          //   quick_tax_details: props.item.quick_tax_details,
          //   total_tax: props.item.taxBM + props.item.taxPPH + props.item.taxPPN,
          //   category_id: this.idCategory,
          //   hscode: this.idHSCodes,
          // }
          this.showLoading({ text: 'Saving...' })
          this.addCategoryRulesDialog = false
          var lastCatId = !isEmpty(this.idCategory) ? last(this.idCategory) : null

          const payload: any = {
            weight: this.addCategoryRules.weight,
            weight_unit: this.addCategoryRules.weightUnit,
            description: this.addCategoryRules.description,
            qty_cbm: this.addCategoryRules.cbm,
            // min_product_order: this.addCategoryRules.minSkuOrder,
            // min_level_3_order: this.addCategoryRules.minLevel3Order,
            category_format: this.addCategoryRules.category_format,
            // note: lowerCase(this.addCategoryRules.note),
            can_wholesale: this.addCategoryRules.can_wholesale === null ? 0 : this.addCategoryRules.can_wholesale,
            can_retail: this.addCategoryRules.can_retail === null ? 0 : this.addCategoryRules.can_retail,
            // quick_tax_details: this.addCategoryRules.quick_tax_details,
            // total_tax: this.addCategoryRules.totalTax,
            // category_id: lastCatId,
            hscode: this.addCategoryRules.hscode
          }

          if (lastCatId) {
            payload.category_id = lastCatId
          }

          if (this.addNew || this.editNew) {
            // const qtd = [{ label: 'bm', value: props.item.taxBM }, { label: 'ppn', value: props.item.taxPPN }, { label: 'pph', value: props.item.taxPPH }]
            // const qtd = [{ label: 'bm', value: this.addCategoryRules.taxBM }, { label: 'ppn', value: this.addCategoryRules.taxPPN }, { label: 'pph', value: this.addCategoryRules.taxPPH }]
            // payload.quick_tax_details = qtd

            await Rules.saveRule(payload)
              .then(insert => {
                if (insert.status === 200) {
                  this.showSnackbar({ text: 'Saved Successfully!', color: 'green', timeout: 1500 })
                } else {
                  this.showSnackbar({ text: 'Saved Unsuccessfully!', color: 'red', timeout: 1500 })
                }
              })
              .catch(error => this.catchHandler(error))
          } else {
            await Rules.putCategoryRules(payload, this.addCategoryRules.id)
              .then(update => {
                if (update.status === 200) {
                  this.showSnackbar({ text: 'Updated Successfully!', color: 'green', timeout: 1500 })
                } else {
                  this.showSnackbar({ text: 'Updated Unsuccessfully!', color: 'red', timeout: 1500 })
                }
              })
              .catch(error => this.catchHandler(error))
          }
          this.getCategoryRulesList()
        } catch (error) {
          this.catchHandler(error)
        } finally {
          this.closeLoading()
          // props.item.saveLoading = false
          // location.reload()
        }
      } else {
        this.showSnackbar({
          text: 'Please fill all the required filed(s)',
          color: 'red',
          timeout: 3500
        })
      }
    })
  }
  isEmpty(variable) {
    return isEmpty(variable)
  }

  async getCategoryRulesList() {
    try {
      const opts: any = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          'sort': this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
          'included': 'hscodes, countries'
        }
      }

      if (this.searchInput != '') {
        opts.params = {
          ...opts.params,
          'filter[description][like]': this.searchInput
        }
      } else {
        var textFile = 'filter[description][like]'
        delete opts.params[textFile]
      }

      this.tableLoadingList = true
      const responseCategoryRules = await Rules.getCategoryRules(opts)
      const responseDataList = responseCategoryRules.data.data
      this.categoryrulelist = responseDataList
      const responseIncludeDataList = responseCategoryRules.data.included
      this.tableItemsList = []
      // isi data tabel
      var thisglobal = this
      forEach(responseDataList, dataCategoryRules => {
        var checkStatus: number | string = 0

        // find hscode
        var codehscode = '-'
        var idHSCodes = []
        var countryId = ''
        var hscodesSelected = []
        if (!isEmpty(dataCategoryRules.relationships.hscodes)) {
          const firsthscode = dataCategoryRules.relationships.hscodes[0].id
          countryId = responseIncludeDataList.hscodes[firsthscode].attributes.country_id
          codehscode = ''
          forEach(dataCategoryRules.relationships.hscodes, listHsCode => {
            const idhscodelist = listHsCode.id
            // codehscode = responseIncludeDataList.hscodes[idhscodelist].attributes.code
            // idHSCodes = responseIncludeDataList.hscodes[idhscodelist].attributes.id
            codehscode += `${responseIncludeDataList.hscodes[idhscodelist].attributes.code}<br/>`
            idHSCodes.push(responseIncludeDataList.hscodes[idhscodelist].attributes.id.toString())
            hscodesSelected.push(responseIncludeDataList.hscodes[idhscodelist])
          })
        }
        var productorder = '-'
        // find min product order 
        if (dataCategoryRules.attributes.min_product_order !== undefined) {
          productorder = dataCategoryRules.attributes.min_product_order
        }
        //find min level 3 order
        var level3order = '-'
        if (dataCategoryRules.attributes.min_level_3_order !== undefined) {
          level3order = dataCategoryRules.attributes.min_level_3_order
        }
        //find qty cbm
        var qtycbm = '-'
        if (dataCategoryRules.attributes.qty_cbm !== undefined) {
          qtycbm = dataCategoryRules.attributes.qty_cbm
        }
        //find description
        var description = '-'
        if (dataCategoryRules.attributes.description !== '') {
          description = dataCategoryRules.attributes.description
        }
        //category name
        var categoryname3 = '-'
        var categorynamelist = '-'
        var category_id = ''
        var category_selected = []
        var newCatId = null
        console.log(dataCategoryRules, 'data category rules')
        if (!isEmpty(dataCategoryRules.relationships.categories)) {
          newCatId = dataCategoryRules.relationships.categories[0].id
        }
        var categoryname3 = ''
        if (!isEmpty(dataCategoryRules.relationships.categories)) {
          category_id = dataCategoryRules.relationships.categories[0].id
          categoryname3 = responseIncludeDataList.categories[category_id].attributes.name
          //   const responseCategoriesParent = await Rules.getParent(category_id)
          //   const responseIncludeCategories = responseCategoriesParent.data.included
          //   forEach(responseIncludeCategories.parent, categoryinclude => {
          //     if (categoryinclude.attributes !== undefined) {
          //       categorynamelist += categoryinclude.attributes.name + ' > '
          //       category_selected[categoryinclude.attributes.level] = {
          //         id: categoryinclude.attributes.id,
          //         name: categoryinclude.attributes.name
          //       }
          //     }
          //   })
          //   categorynamelist += categoryname3
          //   category_selected.push({
          //     id: responseIncludeDataList.categories[category_id].attributes.id,
          //     name: responseIncludeDataList.categories[category_id].attributes.name
          //   })
        }

        // if (categorynamelist === '') {
        //   categorynamelist = '-'
        // }

        // mark incomplete rule
        // if (codehscode === '-' || categorynamelist === '-' || productorder === '-' || level3order === '-' || description === '-' || qtycbm === '-') {
        //   checkStatus = 'Incompleted Rules'
        // } else {
        //   checkStatus = 'Have Completed Rules'
        // }

        const categoryrule = {
          lastCatName: categoryname3,
          newCatId: newCatId,
          id: parseInt(dataCategoryRules.attributes.id),
          description: dataCategoryRules.attributes.description,
          name: categorynamelist,
          qty_cbm: dataCategoryRules.attributes.qty_cbm,
          // min_level_3_order: dataCategoryRules.attributes.min_level_3_order,
          // min_product_order: dataCategoryRules.attributes.min_product_order,
          hscodes: codehscode,
          id_hscode: idHSCodes,
          hscodes_selected: hscodesSelected,
          country: countryId,
          status: checkStatus,
          weight: dataCategoryRules.attributes.weight,
          weight_unit: dataCategoryRules.attributes.weight_unit,
          // note: dataCategoryRules.attributes.note,
          category_format: dataCategoryRules.attributes.category_format,
          // quick_tax_details: dataCategoryRules.attributes.quick_tax_details,
          category_id: category_id,
          category_selected: category_selected,
          actionFab: false
        }
        thisglobal.tableItemsList.push(categoryrule)
        // if (thisglobal.filterSelected === '' || thisglobal.filterSelected === undefined) {
        //   if (find(thisglobal.tableItemsList, item => item.id === categoryrule.id) === undefined) {
        //     thisglobal.tableItemsList.push(categoryrule)
        //   }
        // } else if (thisglobal.filterSelected === categoryrule.status) {
        //   if (find(thisglobal.tableItemsList, item => item.id === categoryrule.id) === undefined) {
        //     thisglobal.tableItemsList.push(categoryrule)
        //   }
        // }
      })

      forEach(thisglobal.tableItemsList, async list => {
        // list.name = 'ext'
        if (list.newCatId !== null) {
          const responseCategoriesParent = await Rules.getParent(list.newCatId)
          const responseIncludeCategories = responseCategoriesParent.data.included
          // list.name = responseCategoriesParent.data.data.attributes.name
          var categorynamelist = ''
          var category_selected = []
          forEach(responseIncludeCategories.parent, categoryinclude => {
            if (categoryinclude.attributes !== undefined) {
              if(categoryinclude.attributes.level == 1 && categorynamelist != ''){
                categorynamelist = categoryinclude.attributes.name + ' > ' + categorynamelist
              }else{
                categorynamelist += categoryinclude.attributes.name + ' > '
              }
              category_selected[categoryinclude.attributes.level] = {
                id: categoryinclude.attributes.id,
                name: categoryinclude.attributes.name
              }
            }
          })
          // const responseIncludeCategories = responseCategoriesParent.data.included
          categorynamelist += list.lastCatName
          var level = responseIncludeDataList.categories[list.category_id].attributes.level
          category_selected[level] = {
            id: responseIncludeDataList.categories[list.category_id].attributes.id,
            name: responseIncludeDataList.categories[list.category_id].attributes.name
          }
          list.category_selected = category_selected
          list.name = categorynamelist
        }
      })
      this.tableTotalItemsList = responseCategoryRules.data.meta.pagination.total
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoadingList = false
    }
  }

  openDeleteModal(props) {
    this.selectedCategoryRule = props.item
    this.selectedDelete = props.item
    this.showDeleteModal = true
  }

  async editCategoryRulesList(id) {
    try {
      this.tableLoadingEdit = true
      this.editNew = false
      this.idHSCodes = ''
      this.idCountry = ''
      this.defaultRule = []
      this.tableItemsEdit = []
      var editRules = find(this.categoryrulelist, item => item.id === id.toString())
      var categoryid3 = editRules.relationships.categories[0].id
      const responseCategory = await Categories.getCategoryById(categoryid3)
      const responseIncludeCategory = responseCategory.data.included.rules

      if (isEmpty(responseIncludeCategory)) {
        this.editNew = true
        this.defaultRule.can_retail = 0
        this.defaultRule.can_wholesale = 0
        this.defaultRule.category_format = 'wholesale:' + lowerCase(this.categories.level_1.selected.name) + ':' + lowerCase(this.categories.level_2.selected.name) + ':' + lowerCase(this.categories.level_3.selected.name) + ':default'
        this.defaultRule.id = 'Default'
        this.defaultRule.min_level_3_order = null
        this.defaultRule.min_product_order = null
        this.defaultRule.note = 'o'
        this.defaultRule.qty_cbm = null
        this.defaultRule.quick_tax_details = null
        this.defaultRule.total_tax = 0
        this.defaultRule.taxBM = 0
        this.defaultRule.taxPPN = 0
        this.defaultRule.taxPPH = 0
        this.defaultRule.weight = null
        this.defaultRule.weight_unit = 'KG'
        this.tableItemsEdit.push(this.defaultRule)
      } else {
        forEach(responseIncludeCategory, categoryRules => {
          Rules.getCategoryRulesById(categoryRules.id)
            .then(responseCategoryRules => {
              const responseDataCategoryRules = responseCategoryRules.data.data
              const responseIncludeCategoryRules = responseCategoryRules.data.included
              this.defaultRule = responseDataCategoryRules.attributes
              if (isEmpty(responseDataCategoryRules.relationships.hscodes)) {
                this.defaultRule.taxBM = 0
                this.defaultRule.taxPPN = 0
                this.defaultRule.taxPPH = 0
                this.getHscodes()
              } else {
                this.idHSCodes = responseDataCategoryRules.relationships.hscodes[0].id
                this.idCountry = responseIncludeCategoryRules.hscodes[this.idHSCodes].attributes.country_id
                this.getHscodes()
                this.getHscodesById(responseDataCategoryRules.relationships.hscodes[0].id)
              }
              this.defaultRule.weight_unit = upperCase(this.defaultRule.weight_unit)
              this.defaultRule.category_level_4 = responseDataCategoryRules.attributes.id
              this.tableItemsEdit.push(this.defaultRule)
            })
            .catch(error => this.catchHandler(error))
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoadingEdit = false
    }
  }

  async deleteCategoryRulesList() {
    try {
      const payload = {
        id: [this.selectedCategoryRule.id],
        action: 'delete'
      }
      this.showLoading({ text: 'Saving...' })
      this.showDeleteModal = false
      const response = await Rules.deleteCategoryRules(this.selectedCategoryRule.id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      this.getCategoryRulesList()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openCreateDialog() {
    this.addCategoryRulesDialog = true
    this.addNew = true
    this.idCategory = []
    this.categories.level_1.selected = ''
    this.categories.level_2.selected = ''
    this.categories.level_3.selected = ''
    this.categories.level_2.items = []
    this.categories.level_3.items = []

    this.getCategories()
    this.getCountries()
    this.getHscodes()

  }

  closeCreateEditDialog() {
    this.addCategoryRulesDialog = false
    this.addCategoryRules = {
      description: '',
      weight: '',
      weightUnit: '',
      cbm: '',
      // note: '',
      // minSkuOrder: '',
      // minLevel3Order: '',
      // country: '',
      hscode: [],
      // taxBM: 0,
      // taxPPN: 0,
      // taxPPH: 0,
      // totalTax: 0,
      category_format: 'unknown',
      can_wholesale: 0,
      can_retail: 0,
      // quick_tax_details: null,
      category: ''
    }
  }

  async openEditDialog(props) {
    this.addCategoryRulesDialog = true
    this.addNew = false
    this.categories.level_1.selected = ''
    this.categories.level_2.selected = ''
    this.categories.level_3.selected = ''
    this.categories.level_2.items = []
    this.categories.level_3.items = []
    this.idCategory = props.item.category_selected
    this.addCategoryRules = {
      id: props.item.id,
      description: props.item.description,
      weight: props.item.weight,
      weightUnit: upperCase(props.item.weight_unit),
      cbm: props.item.qty_cbm,
      // note: props.item.note,
      // minSkuOrder: props.item.min_product_order,
      // minLevel3Order: props.item.min_level_3_order,
      // country: props.item.country,
      hscode: props.item.id_hscode,
      // taxBM: 0,
      // taxPPN: 0,
      // taxPPH: 0,
      // totalTax: 0,
      category_format: props.item.category_format,
      can_wholesale: 0,
      can_retail: 0
      // quick_tax_details: props.item.quick_tax_details,
    }

    this.getCategories()
    this.getCountries()

    // if (this.addCategoryRules.country) {
    this.getHscodes()
    // this.getHscodesById(this.addCategoryRules.hscode)
    // }

    if (!isEmpty(props.item.category_selected)) {

      this.categories.level_1.selected = props.item.category_selected[1]
      await this.onCategorylevel1Changed(props.item.category_selected[1])
      this.categories.level_2.selected = props.item.category_selected[2]
      await this.onCategorylevel2Changed(props.item.category_selected[2])
      this.categories.level_3.selected = props.item.category_selected[3]
      await this.onCategorylevel3Changed(props.item.category_selected[3])
    }
  }

  openAttachCategoryDialog(props) {
    this.attachCategoryDialog = true
    this.addNew = false
    this.categories.level_1.selected = ''
    this.categories.level_2.selected = ''
    this.categories.level_3.selected = ''
    this.attachCategory = {
      id: props.item.id,
      description: props.item.description,
      weight: props.item.weight,
      weightUnit: upperCase(props.item.weight_unit),
      cbm: props.item.qty_cbm,
      hscode: props.item.hscodes_selected,
      category_format: props.item.category_format,
      can_wholesale: 0,
      can_retail: 0,
      country: ''
    }
    if (!isEmpty(props.item.category_selected)) {
      this.categories.level_1.selected = props.item.category_selected[1]
      this.categories.level_2.selected = props.item.category_selected[2]
      this.categories.level_3.selected = props.item.category_selected[3]
    }
    this.getCountries()
    this.getHscodes()

  }

  saveAttachCategory() {
    this.$validator.validateAll('attachCategoryScope').then(async response => {
      if (response) {
        try {
          this.attachCategoryDialog = false
          this.showLoading({ text: 'Saving...' })
          var lastCatId = !isEmpty(this.idCategory) ? last(this.idCategory) : ''
          var idhscodes = []
          if (!isEmpty(this.attachCategory.hscode)) {
            forEach(this.attachCategory.hscode, hscodes => {
              idhscodes.push(hscodes.attributes.id)
            })
          }
          const payload = {
            weight: this.attachCategory.weight,
            weight_unit: this.attachCategory.weightUnit,
            description: this.attachCategory.description,
            qty_cbm: this.attachCategory.cbm,
            category_format: this.attachCategory.category_format,
            can_wholesale: this.attachCategory.can_wholesale === null ? 0 : this.attachCategory.can_wholesale,
            can_retail: this.attachCategory.can_retail === null ? 0 : this.attachCategory.can_retail,
            hscode: idhscodes
          }

          await Rules.putCategoryRules(payload, this.attachCategory.id)
            .then(update => {
              if (update.status === 200) {
                this.showSnackbar({ text: 'Updated Successfully!', color: 'green', timeout: 1500 })
              } else {
                this.showSnackbar({ text: 'Updated Unsuccessfully!', color: 'red', timeout: 1500 })
              }
            })
            .catch(error => this.catchHandler(error))
          this.getCategoryRulesList()
        } catch (error) {
          this.catchHandler(error)
        } finally {
          this.closeLoading()
        }
      } else {
        this.showSnackbar({
          text: 'Please fill all the required field(s)',
          color: 'red',
          timeout: 3500
        })
      }
    })
  }
}
